import { memo } from "react";
import { Box, Image } from "@chakra-ui/react";
import { FaTrash } from "react-icons/fa";
import { IconButton } from "@chakra-ui/react";

const PhotoCard = ({ photo, profile, onDelete }) => {
  return (
    <Box>
      {onDelete ? (
        <IconButton
          ml={4}
          mb={-24}
          aria-label="Delete Media"
          icon={<FaTrash color="red" />}
          onClick={() => onDelete(photo)}
        />
      ) : null}
      <Image
        width="100%"
        src={photo.media_url}
        alt={profile.firstname}
        rounded="lg"
        objectFit={"contain"}
        shadow="lg"
        mx="auto"
        fallbackSrc="/photo-placeholder-350x350.png"
      />
    </Box>
  );
};

export default memo(PhotoCard);
