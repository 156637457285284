import { memo } from "react";
import { Box, Text, Stack, CircularProgress } from "@chakra-ui/react";
import ServiceCard from "components/ServiceCard";
import useApi from "hooks/useApi";
import { useRouter } from "next/router";

const ServicesCard = ({ profile }) => {
  const apiUrl = "/api/item/provider-services";
  const url = `${apiUrl}?provider=${profile.id}`;
  const router = useRouter();

  const onBookNow = (service) => {
    router.push(`/checkout?provider=${profile.id}&service=${service.id}`);
  }

  const {
    data: { result },
    error,
    loading,
  } = useApi(url);

  return (
    <>
      {error && (
        <Stack align={"center"}>
          <Text as="h2" color={"red.500"} fontSize="lg">
            {error}
          </Text>
        </Stack>
      )}
      {loading && (
        <Stack align={"center"} mt={20}>
          <CircularProgress mx={"auto"} isIndeterminate color="black" />
        </Stack>
      )}

      {result && result.length > 0 && (
        <Box py={6} px={2}>
          <Text
            color={"gray.500"}
            textTransform={"uppercase"}
            fontWeight={800}
            fontSize={"3xl"}
            fontFamily={"YesevaOne"}
            letterSpacing={1.1}
            marginBottom={5}
          >
            {profile.firstname}&apos;s Services
          </Text>
          {result.map((service) => (
            <ServiceCard key={service.id} service={service} action={onBookNow} canBook={profile.verified} />
          ))}
        </Box>
      )}
    </>
  );
};

export default memo(ServicesCard);
