import { memo } from "react";
import { Box, Text, } from "@chakra-ui/react";
import { stringToArray } from "utils/helpers";
import StyledPillCloud from "./StyledPillCloud";

const ProviderExpertiseCard = ({ profile }) => {
  const expertiseAreas = stringToArray(profile.expertise_area);
  return (
    <Box py={6} px={2}>
      <Text
        color={"gray.500"}
        textTransform={"uppercase"}
        fontWeight={800}
        fontSize={"3xl"}
        fontFamily={"YesevaOne"}
        letterSpacing={1.1}
        marginBottom={5}
      >
        {profile.firstname}&apos;s Expertise
      </Text>
      <StyledPillCloud items={expertiseAreas}/>
    </Box>
  );
};

export default memo(ProviderExpertiseCard);
