import { memo } from "react";
import { Box, CircularProgress, Stack, Text } from "@chakra-ui/react";
import PhotoCard from "components/PhotoCard";
import useApi from "hooks/useApi";

const PhotosCard = ({ profile }) => {
  const apiUrl = "/api/item/provider-gallery";
  const url = `${apiUrl}?provider=${profile.id}`;

  const {
    data: { result },
    error,
    loading,
  } = useApi(url);

  return (
    <>
      {error && (
        <Stack align={"center"}>
          <Text as="h2" color={"red.500"} fontSize="lg">
            {error}
          </Text>
        </Stack>
      )}
      {loading && (
        <Stack align={"center"} mt={20}>
          <CircularProgress mx={"auto"} isIndeterminate color="black" />
        </Stack>
      )}

      {result && result.length > 0 && (
        <Box py={6}>
          <Text
            color={"gray.500"}
            textTransform={"uppercase"}
            fontWeight={800}
            fontSize={"3xl"}
            fontFamily={"YesevaOne"}
            letterSpacing={1.1}
            marginBottom={5}
          >
            {profile.firstname}&apos;s Gallery
          </Text>
          {result.map((photo) => (
            <Box my={8} key={photo.id}>
              <PhotoCard photo={photo} profile={profile} />
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};

export default memo(PhotosCard);
