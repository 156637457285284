import useSWR from "swr";

const fetcher = (...args) =>
  fetch(...args)
    .then((res) => res.json())
    .catch((err) => err);

const useApi = (url) => {
  const { data } = useSWR(url, fetcher);
  const loading = !data?.error && !data?.result;
  const { result, meta } = data || {};
  const error = data?.error;
  return { data: { result, meta }, error, loading };
};

export default useApi;
