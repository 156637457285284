import { currencySymbol, PLATFORM_PERCENTAGE } from "./constants";

export const formatToCurrency = (amount, currency) => {
  return isNaN(amount)
    ? "Invalid Value"
    : `${currency}${amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
};

export const calculatePayout = (amount) => {
  if (!amount || isNaN(amount) || amount <= 0) {
    return 0;
  }

  const platformFee = amount * PLATFORM_PERCENTAGE;
  return amount - platformFee;
};

export const getCurrencyTypeFromSymbol = (_symbol) => {
  for (let key in currencySymbol) {
    if (currencySymbol.hasOwnProperty(key) && currencySymbol[key] === _symbol) {
      return key;
    }
  }
  return null;
};
