import { Wrap, WrapItem, Tag } from "@chakra-ui/react";

const StyledPillCloud = ({ items }) => {
  return (
    <Wrap spacing="15px" justify="center">
      {items.map((expertise, index) => (
        <WrapItem key={index}>
          <Tag
            size="lg"
            borderRadius="full"
            variant="solid"
            colorScheme="gray"
            px={4}
            py={2}
          >
            {expertise}
          </Tag>
        </WrapItem>
      ))}
    </Wrap>
  );
};

export default StyledPillCloud;
