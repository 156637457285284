import { memo } from "react";
const { Button } = require("@chakra-ui/react");

const StyledButton = ({
  text,
  type,
  bg = "black",
  color = "white",
  ...rest
}) => {
  return (
    <Button
      {...rest}
      type={type}
      bg={bg}
      color={color}
      rounded={"md"}
      _hover={{
        transform: "translateY(-2px)",
        boxShadow: "lg",
      }}
    >
      {text}
    </Button>
  );
};

export default memo(StyledButton);
