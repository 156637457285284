import { memo } from "react";
import { Box, Flex, chakra } from "@chakra-ui/react";
import { formatToCurrency } from "utils/currencyFormatter";
import { toTitleCase } from "utils/textFormatter";
import StyledButton from "./StyledButton";

const ServiceCard = ({
  service,
  action,
  canBook,
  padding = 10,
  bg = "#F9F9F9",
}) => {
  const { title, description, fee, currency_symbol, duration } = service;
  return (
    <Flex
      direction={{ base: "column-reverse", md: "row" }}
      width={"full"}
      rounded={"xl"}
      p={padding}
      justifyContent={"space-between"}
      position={"relative"}
      bg={bg}
      marginBottom={10}
    >
      <Flex
        direction={"column"}
        textAlign={"left"}
        justifyContent={"space-between"}
      >
        <chakra.p
          fontFamily={"YesevaOne"}
          fontWeight={"medium"}
          fontSize={"18px"}
          pb={4}
        >
          {toTitleCase(title)}
        </chakra.p>
        <chakra.p
          fontWeight={"medium"}
          fontSize={"16px"}
          color={"gray.900"}
          pb={4}
        >
          {description}
        </chakra.p>
        <chakra.p fontWeight={"bold"} fontSize={18}>
          {formatToCurrency(fee, currency_symbol)}
          <chakra.span fontWeight={"medium"} color={"gray.700"}>
            {" "}
            for {duration}
          </chakra.span>
        </chakra.p>
        {canBook ? (
          <Box pt={4}>
            <StyledButton text={"Book Now"} onClick={() => action(service)} />
          </Box>
        ) : null}
      </Flex>
    </Flex>
  );
};

export default memo(ServiceCard);
